import { DEV_MODE } from 'config';
import { IObject } from 'models';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getObject, setObject, beforePageChange } from 'services/app';
import { onPageChange } from 'services/app/actions';
import { getObjectLoaded } from 'services/app/selectors';

const useSetPage = () => {
  const dispatch = useDispatch();
  const currentObject = useSelector(getObject);
  const loaded = useSelector(getObjectLoaded);

  const onObjectUpdate = useCallback((doc: IObject | null, isLoaded: boolean) => {
    dispatch(setObject(doc, isLoaded));
  }, []);

  const setPage = useCallback((object: IObject | null, isLoaded: boolean) => {
    if (!isLoaded) {
      return;
    }

    // first realtime document load. object is on reducer, but loaded flag is false
    if (!loaded) {
      return onObjectUpdate(object, true);
    }

    // navigating through pages
    if (object?._id !== currentObject._id) {
      dispatch(beforePageChange());
      onObjectUpdate(object, true);
      return dispatch(onPageChange(currentObject, object as IObject));
    }

    // page got updated
    const modifiedCheck = object.modified && currentObject.modified && object.modified > currentObject.modified;
    const lastModifiedCheck = object.lastModified && currentObject.lastModified && object.lastModified > currentObject.lastModified;
    if (modifiedCheck || lastModifiedCheck) {
      onObjectUpdate(object, true);
    } else if (DEV_MODE) {
      // Keeping this for future debugging
      // tslint:disable:next-line no-console
      console.warn('Object update received, but no action was taken due to modified check');
    }
  }, [onObjectUpdate, currentObject?.modified, currentObject?.slug, loaded]);

  return setPage;
};

export default useSetPage;
