import { ComponentType, useEffect, useReducer } from 'react';
import useSelector from './use-selector';
import PageEditor from 'components/admin2/PageEditor';
import CommunityManager from 'components/admin-bridge/CommunityManager';
import AchievementLibrary from 'components/admin-bridge/AchievementLibrary';
import EarningsDashboard from 'components/admin-bridge/EarningsDashboard';
import AnalyticsInsights from 'components/admin2/Analytics/Insights';
import Videos from 'components/admin2/Analytics/Videos';
import { isDesktopLayout } from 'services/device/selectors';
import {
  isManagingAchievements,
  isManagingCommunity,
  isManagingPages,
  isViewingEarnings,
  isViewingInsights,
  isViewingAnalyticsVideos,
} from 'services/admin/selectors';

export enum TabName {
  AchievementLibrary = 'adminbarAchievements',
  CommunityManager = 'adminbarCommunity',
  EarningsDashboard = 'adminbarEarnings',
  PageEditor = 'adminbarChannels',
  AnalyticsInsights = 'adminbarInsights',
  AnalyticsVideos = 'adminbarVideos',
}


interface IState {
  Component: ComponentType | null;
  isRenderingAdmin: boolean;
  tabName: TabName | null;
}

const initialState: IState = {
  Component: null,
  isRenderingAdmin: false,
  tabName: null,
};
interface IAction {
  Component: null | ComponentType,
  tabName: null | TabName;
}
const reducer = (state: IState, action: IAction): IState => {
  if (action.tabName && action.Component) {
    return {
      ...state,
      Component: action.Component,
      isRenderingAdmin: true,
      tabName: action.tabName,
    };
  }
  return initialState;
};

const useRenderAdmin = (): IState => {
  const desktopLayout = useSelector(isDesktopLayout);
  const managingAchievements = useSelector(isManagingAchievements);
  const managingCommunity = useSelector(isManagingCommunity);
  const managingPages = useSelector(isManagingPages);
  const viewingEarnings = useSelector(isViewingEarnings);
  const viewingAnalyticsInsights = useSelector(isViewingInsights);
  const viewingVideos = useSelector(isViewingAnalyticsVideos);
  const [state, updateAdmin] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!desktopLayout) {
      return;
    }
    if (managingAchievements) {
      updateAdmin({
        Component: AchievementLibrary,
        tabName: TabName.AchievementLibrary,
      });
      return;
    }
    if (managingCommunity) {
      updateAdmin({
        Component: CommunityManager,
        tabName: TabName.CommunityManager,
      });
      return;
    }
    if (managingPages) {
      updateAdmin({
        Component: PageEditor,
        tabName: TabName.PageEditor,
      });
      return;
    }
    if (viewingEarnings) {
      updateAdmin({
        Component: EarningsDashboard,
        tabName: TabName.EarningsDashboard,
      });
      return;
    }
    if (viewingAnalyticsInsights) {
      updateAdmin({
        Component: AnalyticsInsights,
        tabName: TabName.AnalyticsInsights,
      });
      return;
    }
    if (viewingVideos) {
      updateAdmin({
        Component: Videos,
        tabName: TabName.AnalyticsVideos,
      });
      return;
    }
    updateAdmin({ Component: null, tabName: null });
  }, [
    desktopLayout,
    managingAchievements,
    managingCommunity,
    managingPages,
    viewingEarnings,
    viewingAnalyticsInsights,
    viewingVideos,
  ]);

  return state;
};

export default useRenderAdmin;

